import React, { forwardRef } from "react";
import BannerBg from "../../../assets/pages/products/BannerBg.png";
import { BannerText } from "../../Common/BannerText";

interface BannerSectionProps {
  className?: string;
  animate: boolean;
}

const BannerSection = forwardRef(({ className, animate }: BannerSectionProps, ref: any) => {
  return (
    <>
      <section
        className={`relative w-screen h-screen sm:h-screen bg-center bg-cover bg-no-repeat`}
        ref={ref}
        id="banner"
        style={{
          backgroundImage: `url(${BannerBg})`,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <div className="absolute bottom-0 left-0  bg-gradient-to-t from-[#000000e6] opacity-80 w-full h-1/2 z-10" />
        <div className="sm:mx-8 lg:mx-16 xl:mx-28 2xl:mx-auto max-w-screen-lg h-full xl:max-w-screen-xl mx-auto z-20">
          <div
            className="flex justify-center items-start md:items-start 
            h-full max-w-screen-xl 
            sm:mb-20 xl:mb-0 mb-0 
            px-6 sm:px-6 md:px-0 xl:px-0 
            xl:pb-[60px] pb-10 "
          >
            <BannerText animate={animate} color={"white"}>
              Our Products
            </BannerText>
          </div>
        </div>
      </section>
    </>
  );
});

export default BannerSection;
