import React, { useState, Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useForm } from "react-hook-form";
import { isValidEmail } from "./Footer";
import emailjs, { init } from "emailjs-com";
import AlertIcon from "../../assets/common/AlertIcon.svg";
import { PrimaryButton } from "./Button";
import { FeatureProps } from "../../pages/products";
import SelectedIcon from "../../assets/common/SelectedIcon.svg";
import UnSelectedIcon from "../../assets/common/UnSelectedIcon.svg";
import LineOA from "../../assets/pages/products/LineOA.jpg";
import { GrClose } from "react-icons/gr";

interface LineContactFormProps {
  className?: string;
  isOpen: boolean;
  closeModal: () => void;
  selectedProductId?: string[] | undefined;
  allProducts: any[];
  selectProduct: (id: string) => void;
}

init("PWWdemyAn4Mkav8A9");

function LineContactForm({ isOpen, closeModal, selectedProductId, allProducts, selectProduct }: LineContactFormProps) {
  const [loading, setLoading] = React.useState(false);
  const [doneSending, setDoneSending] = React.useState(false);
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const handleEmailValidation = (email: string) => {
    const isValid = isValidEmail(email);
    const validityChanged = (errors.email && isValid) || (!errors.email && !isValid);
    if (validityChanged) {
      // console.log('Fire tracker with', isValid ? 'Valid' : 'Invalid')
    }
    return isValid;
  };

  const onSubmit = (data: any) => {
    setLoading(true);
    emailjs
      .send(
        "service_553b6f7",
        "template_2m3g62b",
        {
          from_name: data["fullName"],
          email: data["email"],
          contact_number: data["phoneNumber"],
          products_of_interest: selectedProductId
            ? selectedProductId.length === 0
              ? "No products selected"
              : selectedProductId.toString()
            : "No products selected",
        },
        "PWWdemyAn4Mkav8A9"
      )
      .then(
        (result: any) => {
          setDoneSending(true);
          setValue("fullName", undefined);
          setValue("birthday", undefined);
          setValue("email", undefined);
          setValue("phoneNumber", undefined);
        },
        (error: any) => {
          setLoading(false);
        }
      );
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="fixed inset-0 z-50 overflow-y-auto" onClose={closeModal}>
        <div className="min-h-screen px-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-[#000000c6]" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="inline-block h-screen align-middle" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block lg:max-w-screen-lg p-6 my-8 overflow-hidden text-left align-middle justify-content transition-all transform bg-white shadow-xl rounded-2xl">
              <div
                className="relative w-full h-full flex flex-col items-center rounded-[20px] 
                  pt-0 md:pt-8 xl:pt-8 
                  pb-3 md:pb-8 xl:pb-8
                  px-0 sm:px-4 md:px-8 xl:px-8"
              >
                <GrClose className="absolute top-2 right-[0px] md:top-4 md:right-4 cursor-pointer" size={24} onClick={closeModal} />
                <h4 className="text-medMainGreen w-full text-[28px] md:text-h2 text-left mb-4">LINE Official Account</h4>
                <caption className="text-b3 md:text-b2 text-darkNight w-full text-left pb-8 md:pb-14">
                  Please add our LINE Official Account to order our products.
                </caption>
                <img src={LineOA} alt="line" className="w-[240px] h-[240px]" />
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
}

export default LineContactForm;
