import React, { forwardRef } from "react";
import UsedForIcon from "../../../assets/pages/products/UsedForIcon.svg";
import EffectsIcon from "../../../assets/pages/products/EffectsIcon.svg";
import { PrimaryButton } from "../../Common/Button";
import { FeatureProps } from "../../../pages/products";

interface CultivarsCollectionProps {
  className?: string;
  animate?: boolean;
  onClick: (id: string) => void;
  allProducts: FeatureProps[];
}

const CultivarsCollection = forwardRef(({ className, animate, onClick, allProducts }: CultivarsCollectionProps, ref: any) => {
  return (
    <section
      className={`relative h-auto flex flex-col items-center px-4 lg:px-16 py-10 md:py-20
        transition-all ease-out duration-2500 translate-y-10`}
      style={animate ? { opacity: 1, transform: "translateZ(0px)" } : { opacity: 0 }}
      ref={ref}
      id="cultivars"
    >
      <h3 className="sm:font-normal lg:text-h2 sm:text-h3 text-b2 h4 mb-10 md:mb-18 mt-0 text-woodyGreen">Premium Cultivars with Passion</h3>
      <div className="grid grid-cols-12 w-full px-4 md:px-8 xl:px-0 max-w-screen-xl gap-x-4 md:gap-x-10">
        {allProducts.map((data: FeatureProps) => (
          <div className="col-span-12 md:col-span-6 flex flex-col mb-6 md:mb-10">
            <img src={data.image} className="w-full xl:h-[392px] lg:h-[320px] md:h-[280px] h-[240px] rounded-[20px] object-cover" />
            <div className="flex flex-col gap-y-0 mt-4 bg-medLightGrey rounded-[20px] xl:p-6 p-4">
              <h3 className="lg:text-h3 text-b2 sm:text-b1 text-medMainGreen">{data.title}</h3>
              <p className="md:text-b1 sm:text-b2 text-cap text-medMainGreen mb-4 md:mb-6">{data.subTitle}</p>
              <p className="md:text-b2 text-cap text-medGold" style={{ color: `${data.strainColor}` }}>
                {data.strainLabel}
              </p>
              <div className="relative h-6 w-full py-2">
                <div className="w-full h-2 rounded" style={{ background: "linear-gradient(90deg, #B89425 0%, #728A56 50.86%, #438478 100%)" }} />
                <div className={`absolute top-1 h-4 w-4 bg-white border border-medMainGreen rounded-full`} style={{ left: `${data.strainLevel}%` }} />
              </div>
              <div className="flex w-full justify-between md:mb-8 mb-4">
                <p className="text-base md:text-lg text-medGold">SATIVA</p>
                <p className="text-base md:text-lg text-medMainGreen">INDICA</p>
              </div>
              <p className="md:text-b2 text-cap text-[#909090] mb-2">USED FOR</p>
              <div className="mb-6 flex items-center">
                <img src={UsedForIcon} className="w-6 h-6 mr-4" />
                <p className="md:text-b2 text-cap text-medMainGreen">{data.usedFor}</p>
              </div>
              <p className="md:text-b2 text-cap text-[#909090] mb-2">EFFECTS</p>
              <div className="mb-6 flex items-center">
                <img src={EffectsIcon} className="w-6 h-6 mr-4" />
                <p className="md:text-b2 text-cap text-medMainGreen">{data.effects}</p>
              </div>
              <p className="md:text-b2 text-cap text-[#909090] mb-2">TERPENES PROFILE</p>
              <div className="mb-6 flex items-center md:mb-10">
                <p className="md:text-b2 text-cap text-medMainGreen">{data.terpenesProfile}</p>
              </div>
              <PrimaryButton style={{ width: "100%" }} onClick={() => onClick(data.id)}>
                ORDER NOW
              </PrimaryButton>
            </div>
          </div>
        ))}
      </div>
      <p className="text-2xl md:text-[32px] md:leading-[40px] text-[#4B6949] text-center mt-4 md:mt-10">For Upcoming and Exclusive Strains please contact for more Information.</p>
    </section>
  );
});

export default CultivarsCollection;

// <div
//   className="flex justify-center items-start h-[128px] md:h-[240px] col-span-6 md:col-span-3
//   p-2 mb-10 md:p-6 md:px-0 xl:px-3
//   !bg-center !bg-cover !bg-no-repeat"
//   key={data.title}
// >
//   <div className="p-0 z-10 transition-all flex flex-col items-center justify-start">
//     <img src={data.image} className="w-3/4 md:w-[200px] mb-2 md:mb-6" />
//     <h3 className="font-extralight mb-0 mt-0 text-xl md:text-2xl text-medMainGreen text-center">{data.title}</h3>
//   </div>
// </div>
