import React from "react";

export const BannerText = ({ children, animate, color }: any) => {
  return (
    <h1
      className={`pt-[128px] md:pt-[228px] text-[64px] leading-[72px] text-${
        color ? color : "medMainGreen"
      } text-center transition-all ease-out duration-2500 translate-y-10`}
      style={animate ? { opacity: 1, transform: "translateZ(0px)" } : { opacity: 0 }}
    >
      {children}
    </h1>
  );
};
