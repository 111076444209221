import React, { forwardRef } from "react";
import Product1 from "../../../assets/pages/products/Product1.png";
import MarkIcon from "../../../assets/pages/products/MarkIcon.svg";
import { PrimaryButton } from "../../Common/Button";
import BannerBg from "../../../assets/pages/products/BannerBg.png";

interface PrimaryNurserySectionProps {
  className?: string;
  animate?: boolean;
  onClick: (id: string) => void;
}

type FeatureProps = {
  id: string;
  title: string;
  subTitle: string;
  strainLabel: string;
  strainLevel: number;
  strainColor: string;
  usedFor: string;
  effects: string;
  tarpeneseProfile: string;
  image: string;
};

const CollectionsData: FeatureProps[] = [
  {
    id: "CHARLOTTE’S ANGEL",
    title: "CHARLOTTE’S ANGEL",
    subTitle: "BY MEDICANA LABS",
    strainLabel: "Sativa Dominant Hybrid",
    strainLevel: 20,
    strainColor: "#CAB679",
    usedFor: "Anxiety, Pain, Stress",
    effects: "Clear Headed, Calming, Relaxed",
    tarpeneseProfile: "Mycrene (Mango) Caryophyllene (Spice)",
    image: Product1,
  },
  {
    id: "DINAMED CBD PLUS",
    title: "DINAMED CBD PLUS",
    subTitle: "BY MEDICANA LABS",
    strainLabel: "Indica",
    strainLevel: 80,
    strainColor: "#4B6949",
    usedFor: "Anxiety, Pain, Stress",
    effects: "Clear Headed, Calming, Relaxed",
    tarpeneseProfile: "Mycrene (Mango) Caryophyllene (Spice)",
    image: Product1,
  },
];

const PrimaryNurserySection = forwardRef(({ className, animate, onClick }: PrimaryNurserySectionProps, ref: any) => {
  return (
    <section
      className={`relative h-auto flex flex-col items-center px-4 md:px-4 lg:px-16 py-10 md:py-20
        transition-all ease-out duration-2500 translate-y-10`}
      style={animate ? { opacity: 1, transform: "translateZ(0px)" } : { opacity: 0 }}
      ref={ref}
      id="clones"
    >
      <h3 className="font-semibold sm:font-normal lg:text-h2 sm:text-h3 text-b2 h4 mb-10 md:mb-18 mt-0 text-woodyGreen">Premium Nursery</h3>
      <div className="grid grid-cols-12 mb-10 md:mb-0 w-full px-0 sm:px-4 md:px-8 max-w-screen-xl gap-x-4 md:gap-x-10">
        <div className="col-span-12 md:col-span-6 flex flex-col mb-6 md:mb-0 px-4 sm:px-0">
          <img src={BannerBg} className="w-full object-cover xl:h-[504px] lg:h-[460px] md:h-[400px] h-[240px] rounded-[20px]" />
        </div>
        <div className="col-span-12 md:col-span-6 flex flex-col">
          <div className="flex flex-col gap-y-0 rounded-[20px] xl:px-6 px-4">
            <h3 className="font-semibold lg:text-h2 sm:text-h3 text-b2 text-medMainGreen md:mb-8 mb-4">CLONES</h3>
            <ul className="bg-medMintGreen py-4 md:py-6 px-2 md:px-4 mb-8 text-white rounded-[20px]">
              <li className="flex items-center">
                <img src={MarkIcon} className="w-6 h-6 mr-4" />
                <p className="md:text-b2 text-cap">Available in Rockwool</p>
              </li>
              <div className="border-b border-white w-full mt-4 mb-4" />
              <li className="flex items-center">
                <img src={MarkIcon} className="w-6 h-6 mr-4" />
                <p className="md:text-b2 text-cap">Manicured and pre-sorted </p>
              </li>
              <div className="border-b border-white w-full mt-4 mb-4" />
              <li className="mb flex items-center">
                <img src={MarkIcon} className="w-6 h-6 mr-4" />
                <p className="md:text-b2 text-cap">Volumes from 100-1,000* plants per delivery </p>
              </li>
            </ul>
            <p className="md:text-b2 text-cap text-medMainGreen mb-8">
              Cannabis clones enable growers a time and cost-effective method of cultivation from consistent and genetically identical cuttings from
              the mother plant.{" "}
            </p>
            <PrimaryButton style={{ width: "100%" }} onClick={() => onClick("CHARLOTTE’S ANGEL")}>
              ORDER NOW
            </PrimaryButton>
          </div>
        </div>
      </div>
    </section>
  );
});

export default PrimaryNurserySection;
