import React, { useLayoutEffect, useRef, useState } from "react";
import Page from "../components/Common/Page";
import IndexLayout from "../layouts";
import BannerSection from "../components/pages/ProductsPage/BannerSection";
import CultivarsCollection from "../components/pages/ProductsPage/CultivarsCollection";
import PrimaryNurserySection from "../components/pages/ProductsPage/PrimaryNurserySection";
import SalesContactFormDialog from "../components/Common/SalesContactForm";
import start_dawg from "../assets/pages/products/start_dawg.png";
import ultra_thai from "../assets/pages/products/ultra_thai.png";
import durban_poison from "../assets/pages/products/durban_poison.png";
import double_dawg from "../assets/pages/products/double_dawg.png";
import cape_candy from "../assets/pages/products/cape_candy.png";
import strawberry_cookies from "../assets/pages/products/strawberry_cookies.png";
import white_widow from "../assets/pages/products/white_widow.png";
import LineContactForm from "../components/Common/LineContactForm";

const defaultShow = {
  itemOne: false,
  itemTwo: false,
  itemThree: false,
  itemFour: false,
  itemFive: false,
  itemSix: false,
  itemSeven: false,
};

export type FeatureProps = {
  id: string;
  title: string;
  subTitle: string;
  strainLabel: string;
  strainLevel: number;
  strainColor: string;
  usedFor: string;
  effects: string;
  terpenesProfile: string;
  image: string;
};

const CollectionsData: FeatureProps[] = [
  // {
  //   id: "CHARLOTTE’S ANGEL",
  //   title: "CHARLOTTE’S ANGEL",
  //   subTitle: "BY MEDICANA LABS",
  //   strainLabel: "Sativa Dominant Hybrid",
  //   strainLevel: 20,
  //   strainColor: "#CAB679",
  //   usedFor: "Anxiety, Pain, Stress",
  //   effects: "Clear Headed, Calming, Relaxed",
  //   terpenesProfile: "Mycrene (Mango) Caryophyllene (Spice)",
  //   image: CharlotteAngels,
  // },
  // {
  //   id: "DINAMED CBD PLUS",
  //   title: "DINAMED CBD PLUS",
  //   subTitle: "BY MEDICANA LABS",
  //   strainLabel: "Indica",
  //   strainLevel: 80,
  //   strainColor: "#4B6949",
  //   usedFor: "Sleeping Disorders, Relaxation, Pain",
  //   effects: "Uplifted, Happy, Relaxed",
  //   terpenesProfile: "Limonene (Lemon) Mycrene (Mango)",
  //   image: DinamedCBDPlus,
  // },
  {
    id: "STAR DAWG",
    title: "STAR DAWG",
    subTitle: "BY MEDICANA LABS",
    strainLabel: "Hybrid",
    strainLevel: 50,
    strainColor: "#708a58",
    usedFor: "Anxiety, Stress, Pain Relief ",
    effects: "Happy, Uplifting, Euphoric",
    terpenesProfile: "Myrcene (Mango) / Caryophyllene (Pepper)",
    image: start_dawg,
  },
  {
    id: "ULTRA THAI",
    title: "ULTRA THAI",
    subTitle: "BY MEDICANA LABS",
    strainLabel: "Sativa Dominant",
    strainLevel: 20,
    strainColor: "#CAB679",
    usedFor: "Fatigue, Stress, Depression",
    effects: "Happy, Uplifting, Energetic",
    terpenesProfile: "Myrcene (Mango) / Terpinolene (Fruity)",
    image: ultra_thai,
  },
  {
    id: "DURBAN POISON",
    title: "DURBAN POISON",
    subTitle: "BY MEDICANA LABS",
    strainLabel: "Sativa Dominant",
    strainLevel: 20,
    strainColor: "#CAB679",
    usedFor: "Stress, Anxiety, Depression",
    effects: "Uplifting, Energetic, Happy, Creative",
    terpenesProfile: "Myrcene (Herbal) / Terpinolene (Fruity)",
    image: durban_poison,
  },
  {
    id: "DOUBLE DAWG",
    title: "DOUBLE DAWG",
    subTitle: "BY MEDICANA LABS",
    strainLabel: "Hybrid",
    strainLevel: 50,
    strainColor: "#708a58",
    usedFor: "Depression, Fatigue, Stress",
    effects: "Happy, Chill, Euphoric, Talkative",
    terpenesProfile: "Terpinolene / Bisabolo / Humulene",
    image: double_dawg,
  },
  {
    id: "CAPE CANDY",
    title: "CAPE CANDY",
    subTitle: "BY MEDICANA LABS",
    strainLabel: "Sativa Dominant",
    strainLevel: 20,
    strainColor: "#CAB679",
    usedFor: "Anxiety, Fatigue, Stress",
    effects: "Energetic, Calming, Talkative, Mellow",
    terpenesProfile: "Myrcene (Mango) / Caryophyllene / Pinene (Pine)",
    image: cape_candy,
  },
  {
    id: "STRAWBERRY COOKIES",
    title: "STRAWBERRY COOKIES",
    subTitle: "BY MEDICANA LABS",
    strainLabel: "Sativa Dominant",
    strainLevel: 20,
    strainColor: "#CAB679",
    usedFor: "Pain, Stress",
    effects: "Energetic, Talkative, Tingly",
    terpenesProfile: "Myrcene (Mango) / Caryophyllene / Pinene (Pine)",
    image: strawberry_cookies,
  },
  {
    id: "WHITE WIDOW",
    title: "WHITE WIDOW",
    subTitle: "BY MEDICANA LABS",
    strainLabel: "Hybrid",
    strainLevel: 50,
    strainColor: "#708a58",
    usedFor: "Pain, Depression, Fatigue, Headaches",
    effects: "Creative, Energetic, Euphoric, Happy, Uplifting",
    terpenesProfile: "Myrcene (Mango) / Limonene Caryophyllene (Spice)",
    image: white_widow,
  },
];

const IndexPage = () => {
  const [check, setCheck] = useState(false);
  const [show, setShow] = useState<{ [key: string]: boolean }>({ ...defaultShow, navbar: false });
  const [selectedProductId, setSelectedProductId] = useState<string[] | undefined>(undefined);
  const [isOpen, setIsOpen] = useState(false);

  const closeModal = () => {
    setIsOpen(false);
    setSelectedProductId(undefined);
  };

  const selectProduct = (productId: string) => {
    setSelectedProductId([productId]);
    setIsOpen(true);
  };

  const selectMoreProduct = (productId: string) => {
    setSelectedProductId((prev: any) => {
      if (prev) {
        // Check if this id is already in the array
        if (prev.includes(productId)) {
          // If it is, remove it
          return prev.filter((id: string) => id !== productId);
        } else {
          // If it isn't, add it
          return [...prev, productId];
        }
      } else {
        return [productId];
      }
    });
  };

  const [activeStep, setActiveStep] = useState("#banner");

  const refNavbar = useRef(null);
  const refOne = useRef(null);
  const refTwo = useRef(null);
  const refThree = useRef(null);

  useLayoutEffect(() => {
    const bottomPos = (element: any) => element?.getBoundingClientRect().bottom + window.scrollY;

    const section1Bottom = bottomPos(refOne.current);
    const section2Bottom = bottomPos(refTwo.current);

    const onScroll = () => {
      if (window) {
        const scrollPos = window.scrollY + window.innerHeight;
        // Section1
        if (scrollPos < section1Bottom + 16) {
          if (!show["itemOne"]) {
            // setShow(getNewState("itemOne", true));
            setShow((prev: any) => ({ ...prev, ["itemOne"]: true, navbar: false }));
            setActiveStep("#banner");
          }
        }
        // Section2
        else if (section1Bottom + 16 < scrollPos && scrollPos < section2Bottom) {
          if (!show["itemTwo"]) {
            // setShow(getNewState("itemTwo"));
            setShow((prev: any) => ({ ...prev, ["itemTwo"]: true, navbar: true }));
            setActiveStep("#productlist");
          }
        }
        // Section3
        else {
          if (!show["itemThree"]) {
            // setShow(getNewState("itemThree"));
            setShow((prev: any) => ({ ...prev, ["itemThree"]: true, navbar: true }));
            setActiveStep("#treeinformation");
          }
        }
      }
    };

    const initialProp = () => {
      window.removeEventListener("scroll", onScroll);
      window.addEventListener("scroll", onScroll);
      onScroll();
    };

    const changeCheck = () => {
      setCheck(!check);
    };

    if (window) {
      window.addEventListener("scroll", onScroll);
      window.addEventListener("resize", changeCheck);
      initialProp();
    }
    return () => {
      window.removeEventListener("scroll", onScroll);
      window.removeEventListener("resize", changeCheck);
    };
  }, [check]);

  return (
    <IndexLayout ref={refNavbar} animate={show.navbar}>
      <Page>
        <BannerSection ref={refOne} animate={show.itemOne} />
        <CultivarsCollection ref={refTwo} animate={show.itemTwo} onClick={selectProduct} allProducts={CollectionsData} />
        <PrimaryNurserySection ref={refThree} animate={show.itemThree} onClick={selectProduct} />
      </Page>
      {/* <SalesContactFormDialog
        selectedProductId={selectedProductId}
        isOpen={isOpen}
        closeModal={closeModal}
        allProducts={CollectionsData}
        selectProduct={selectMoreProduct}
      /> */}
      <LineContactForm
        selectedProductId={selectedProductId}
        isOpen={isOpen}
        closeModal={closeModal}
        allProducts={CollectionsData}
        selectProduct={selectMoreProduct}
      />
    </IndexLayout>
  );
};

export default IndexPage;
